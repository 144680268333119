import "core-js/modules/es.array.push.js";
import AddPersonTab from '@/components/crm/addresses/addAddress/addPerson/AddPersonTab.vue';
import AddNonPersonTab from '@/components/crm/addresses/addAddress/addNonPerson/AddNonPersonTab.vue';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      navigationList: state => state.crmAddresses.navList,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      validPatient: state => state.crmAddresses.validPatient,
      validRelative: state => state.crmAddresses.validRelative,
      validEmployee: state => state.crmAddresses.validEmployee,
      validDoctor: state => state.crmAddresses.validDoctor,
      validGeneralInfo: state => state.crmAddresses.validGeneralInfo,
      validCompany: state => state.crmAddresses.validCompany,
      person: state => state.crmAddresses.newPerson,
      nonPerson: state => state.crmAddresses.newNonPerson,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  components: {
    AddPersonTab,
    AddNonPersonTab
  },
  data: () => ({
    dialogTab: null
  }),
  methods: {
    refMeTo(tab, ref) {
      const childComponent = this.$refs[tab];
      if (childComponent) {
        const childRef = childComponent.$refs[ref];
        if (childRef) {
          console.log(childRef);
          childRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    },
    isValidPerson() {
      return this.validAddress && (this.person.isPatient && this.validPatient || !this.person.isPatient) && (this.person.isRelative && this.validRelative || !this.person.isRelative) && (this.person.isEmployee && this.validEmployee || !this.person.isEmployee) && (this.person.isDoctor && this.validDoctor || !this.person.isDoctor) && (this.person.isConsumer || this.person.isAdultRepresentation || this.person.isPatient || this.person.isEmployee || this.person.isDoctor);
    },
    isValidNonPerson() {
      return (this.nonPerson.isCompany || this.nonPerson.isAdultRepresentation || this.nonPerson.isResidence) && this.validGeneralInfo && (this.nonPerson.isCompany && this.validCompany || !this.nonPerson.isCompany);
    },
    isValid() {
      if (this.dialogTab == 0) {
        return this.isValidPerson();
      } else if (this.dialogTab == 1) {
        return this.isValidNonPerson();
      }
    },
    save() {
      if (this.dialogTab == 0) {
        //--> person
        this.savePerson();
      } else if (this.dialogTab == 1) {
        //--> non-person
        this.saveNonPerson();
      }
      //this.resetValuesToDefault()
    },
    savePerson() {
      if (this.person.isPatient) {
        //map healthInsurance company to its key
        this.person.patient.healthInsuranceCompany = {
          lfdnr: this.person.patient.healthInsuranceCompany.key.lfdnr,
          firma: this.person.patient.healthInsuranceCompany.key.firma
        };
        if (this.person.isRelative) {
          this.person.patient.mainInsuranceData.healthInsuranceCompany = {
            lfdnr: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.lfdnr,
            firma: this.person.patient.mainInsuranceData.healthInsuranceCompany.key.firma
          };
        }
        this.person.patient.svnr = parseInt(this.person.patient.svnr);
        this.person.patient.familyDoctor = this.person.patient.familyDoctor.key;
        this.person.patient.familyDoctorName = this.person.patient.familyDoctor.familyDoctorName;
        this.person.patient.familyDoctorHvnr = this.person.patient.familyDoctor.hvnr;
      }
      this.$store.dispatch('saveNewPerson', this.person).then(result => {
        this.$router.push({
          name: 'crmAddress',
          params: {
            lfdnr: result.data.key.lfdnr
          }
        });
        this.resetValuesToDefault();
      });
    },
    saveNonPerson() {
      this.$store.dispatch('saveNewNonPerson', this.nonPerson).then(result => {
        this.$router.push({
          name: 'crmAddress',
          params: {
            lfdnr: result.data.key.lfdnr
          }
        });
        this.resetValuesToDefault();
      });
    },
    loadDefaults() {
      this.$store.dispatch('loadAddAddressOptions');
    },
    resetValuesToDefault() {
      this.$store.commit('resetNewPerson');
      this.$store.commit('resetNewNonPerson');
    },
    updatePersonNavList() {
      this.$store.commit('updatePersonNavList');
    },
    updateNonPersonNavList() {
      this.$store.commit('updateNonPersonNavList');
    }
  },
  mounted() {
    this.loadDefaults();
  }
};