/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AddAddress.vue?vue&type=template&id=53e2868e"
import script from "./AddAddress.vue?vue&type=script&lang=js"
export * from "./AddAddress.vue?vue&type=script&lang=js"
import style0 from "./AddAddress.vue?vue&type=style&index=0&id=53e2868e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports