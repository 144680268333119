import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "10",
      "lg": "10",
      "xl": "10",
      "md": "10"
    }
  }, [_c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.dialogTab,
      callback: function ($$v) {
        _vm.dialogTab = $$v;
      },
      expression: "dialogTab"
    }
  }, [_c(VTab, {
    key: "person"
  }, [_vm._v(" Person ")]), _c(VTab, {
    key: "nonPerson"
  }, [_vm._v(" Nichtperson ")])], 1), _c(VTabsItems, {
    staticClass: "pt-3",
    model: {
      value: _vm.dialogTab,
      callback: function ($$v) {
        _vm.dialogTab = $$v;
      },
      expression: "dialogTab"
    }
  }, [_c(VTabItem, {
    key: "Person"
  }, [_c('AddPersonTab', {
    ref: "personTab"
  })], 1), _c(VTabItem, {
    key: "Nichtperson"
  }, [_c('AddNonPersonTab', {
    ref: "nonPersonTab"
  })], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "4",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VContainer, [_c(VNavigationDrawer, {
    staticClass: "pt-15",
    staticStyle: {
      "z-index": "2",
      "height": "100%",
      "overflow-y": "auto"
    },
    attrs: {
      "fixed": "",
      "permanent": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c(VListItem, {
          attrs: {
            "two-line": ""
          }
        }, [_c(VListItemContent, [_vm.dialogTab == 0 ? _c(VChip, {
          staticClass: "mb-3 mt-10 pa-4 text-h6 font-weight-bold primary--text",
          attrs: {
            "outlined": ""
          }
        }, [_c(VIcon, {
          staticClass: "mx-2 primary--text"
        }, [_vm._v("mdi-account-plus")]), _vm._v(" Person")], 1) : _vm._e(), _vm.dialogTab == 1 ? _c(VChip, {
          staticClass: "my-3 mb-3 mt-10 pa-4 text-h6 font-weight-bold primary--text",
          attrs: {
            "outlined": ""
          }
        }, [_c(VIcon, {
          staticClass: "mx-2"
        }, [_vm._v("mdi-office-building-plus")]), _vm._v(" Nicht-Person")], 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function () {
        return [_c(VDivider), _c('div', {
          staticClass: "pa-6",
          staticStyle: {
            "display": "flex",
            "justify-content": "space-around"
          }
        }, [_c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "disabled": !_vm.isValid()
          },
          on: {
            "click": _vm.save
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, [_vm._v("mdi-content-save")])];
            },
            proxy: true
          }])
        }), _c('LBAButton', {
          attrs: {
            "buttonType": 'error'
          },
          on: {
            "click": function ($event) {
              _vm.resetValuesToDefault(), _vm.updatePersonNavList(), _vm.updateNonPersonNavList();
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, [_vm._v("mdi-eraser-variant")])];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm.dialogTab == 0 ? _c(VList, {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.navigationList.personNavList, function (item) {
    return _c(VListItem, {
      key: item.title
    }, [item.show ? _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "primary--text hover-pointer",
      on: {
        "click": function ($event) {
          return _vm.refMeTo('personTab', item.ref);
        }
      }
    }, [_vm._v(_vm._s(item.title))])], 1) : _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "text--disabled"
    }, [_vm._v(_vm._s(item.title))])], 1)], 1);
  }), 1) : _vm._e(), _vm.dialogTab == 1 ? _c(VList, {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.navigationList.nonPersonNavList, function (item) {
    return _c(VListItem, {
      key: item.title
    }, [item.show ? _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "primary--text hover-pointer",
      on: {
        "click": function ($event) {
          return _vm.refMeTo('nonPersonTab', item.ref);
        }
      }
    }, [_vm._v(_vm._s(item.title))])], 1) : _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "text--disabled"
    }, [_vm._v(_vm._s(item.title))])], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };